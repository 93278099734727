module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bitwarden","short_name":"Bitwarden","start_url":"/","background_color":"#ffffff","theme_color":"#175ddc","display":"standalone","icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1abf57d1154002a9fac426a2c5c04d85"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NF32QCS","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/@ninetailed/experience.js-gatsby/gatsby-browser.js'),
      options: {"plugins":[],"clientId":"9f7ee18b-8e18-430a-9548-880809a6183f","environment":"main","ninetailedPlugins":[{"resolve":"@ninetailed/experience.js-plugin-google-tagmanager","options":{}},{"resolve":"@ninetailed/experience.js-plugin-insights","options":{}}],"useSDKEvaluation":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
